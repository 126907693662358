

























import Vue from 'vue'
import request from '@/utils/request'
export default Vue.extend({
  data() {
    return {
        areaData: [],
        formData: {},
        rules: {
            name: [{required: true, message: '单位名称不能为空', trigger: 'blur'}],
            areaId: [{required: true, type: 'array', message: '区域不能为空', trigger: 'change'}],
        },
        tableAttr: {},
        exceptColumn: ['id', 'create_by', 'create_date', 'update_by', 'update_date', 'del_flag'],
        tableName: '',
    }
  },
  mounted() {
    this.tableName  = this.$route.params.tableName
    this.getTableColumn()
  },
  methods: {
      /*toHump(name:string) {
          return name.replace(/\_(\w)/g, function(all, letter){
              return letter.toUpperCase();
          });
      },*/
      getTableColumn() {
          const tableName = this.tableName;
          request.get(`/api/mdpr/table/${tableName}`, null).then((data) => {
              const tableComment: any = {};
              const formInfo: any = {};
              data.forEach((item: any) => {
                  if (this.exceptColumn.indexOf(item.name) === -1) { // 出去额外的数据
                      tableComment[item.name] = item.comment + ':';
                      formInfo[item.name] = '';
                  }
              });
              this.tableAttr = tableComment;
              this.formData = formInfo;
          })
      },
    /*loadAreaData(item: any) {
      const { value } = item
      request.get(`/api/sys/area/children/${value}`, null)
      .then((data) => {
        if (data) {
          item.children = this.buildTreeNode(data)
        }
      })
    },
    buildTreeNode(data: any) {
      if (!data || data.size === 0) {
        return []
      }
      const array: any[] = []
      data.forEach((e: any) => array.push(this.buildNode(e)))
      return array
    },
    buildNode(geoData: any) {
      const { id: value, name: label } = geoData
      const node = {value, label}
      return node
    },*/
    submit() {
      const params = this.formData
      request.post(`/api/mdpr/manage/${this.tableName}`, params).then((res) => {
        this.$Message.success('保存成功!')
        this.$router.replace('/main/sys_manage/infor_manager')
      })
    },
  },
})
