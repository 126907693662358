import { render, staticRenderFns } from "./sys_warnRange_add.vue?vue&type=template&id=3767c4e3&scoped=true&"
import script from "./sys_warnRange_add.vue?vue&type=script&lang=ts&"
export * from "./sys_warnRange_add.vue?vue&type=script&lang=ts&"
import style0 from "./sys_warnRange_add.vue?vue&type=style&index=0&id=3767c4e3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3767c4e3",
  null
  
)

export default component.exports